"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
const sidebar_1 = require("@src/assets/js/sidebar");
const todo_1 = require("@src/assets/js/todo");
const theme_toggle_1 = require("./theme-toggle");
document.addEventListener("DOMContentLoaded", function () {
    if (document.readyState === "interactive" || document.readyState === "complete") {
        sidebar_1.Sidebar.init();
        theme_toggle_1.ThemeToggle.init();
        todo_1.Todo.init();
    }
});
