"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ThemeToggle = void 0;
class ThemeToggle {
    constructor() {
        this.themeToggleButton = document.querySelector(".theme-toggle");
        if (this.themeToggleButton) {
            this.themeToggleButton.addEventListener('click', () => {
                if (document.body.classList.contains("dark")) {
                    window.location.replace("?theme=light");
                }
                else {
                    window.location.replace("?theme=dark");
                }
            });
        }
    }
    static init() {
        new this();
    }
}
exports.ThemeToggle = ThemeToggle;
