"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.confirm = void 0;
const confirm = () => {
    const cancelButton = document.querySelector('.confirm .btn-secondary');
    const okButton = document.querySelector('.confirm .btn-primary');
    return new Promise((resolve, rejext) => {
        if (cancelButton) {
            cancelButton.addEventListener('click', (event) => __awaiter(void 0, void 0, void 0, function* () {
                return resolve(false);
            }));
        }
        if (okButton) {
            okButton.addEventListener('click', (event) => __awaiter(void 0, void 0, void 0, function* () {
                return resolve(true);
            }));
        }
    });
};
exports.confirm = confirm;
